export const getAppHead = ({ host }) => {
  if (host.includes('reverse.fitness') || host.includes('reversefit.fitness')) {
    const title = host.includes('reversefit') ? 'Reversefit Fitness' : 'Reverse Fitness';

    return {
      title,
      meta: [
        { hid: 'og:url', property: 'og:url', content: host },
        { hid: 'og:title', property: 'og:title', content: title },
        {
          hid: 'og:description',
          property: 'og:description',
          content: `${title} is the first fitness program that focuses on female physiology.`,
        },
        {
          hid: 'description',
          name: 'description',
          content: `${title} is the first fitness program that focuses on female physiology.`,
        },
        // FIXME: This is currently being handled by a worker
        // The current value is the same as Reverse Health use case (but this is not the one used in the end)
        {
          hid: 'facebook-domain-verification',
          name: 'facebook-domain-verification',
          content: 'pbnzferzi10qee09g49f28pcvhvgbz',
        },
      ],
      link: [{ hid: 'favicon', rel: 'icon', type: 'image/x-icon', href: '/RF-favicon.ico' }],
    };
  }

  if (host.includes('fitover40.health'))
    return {
      title: 'Fit Over 40',
      meta: [
        { hid: 'og:url', property: 'og:url', content: 'https://fitover40.health' },
        { hid: 'og:title', property: 'og:title', content: 'Fit Over 40' },
        {
          hid: 'og:description',
          property: 'og:description',
          content: 'Fit Over 40 is a fitness app for women over 40.',
        },
        {
          hid: 'description',
          name: 'description',
          content: 'Fit Over 40 is a fitness app for women over 40.',
        },
        {
          hid: 'facebook-domain-verification',
          name: 'facebook-domain-verification',
          content: 'omhlur62nh3mcqd5c6s80b9cwjgzk7',
        },
      ],
      link: [{ hid: 'favicon', rel: 'icon', type: 'image/x-icon', href: '/FO40-favicon.ico' }],
    };

  return {
    title: 'Reverse Health',
    meta: [
      {
        hid: 'description',
        name: 'description',
        content:
          "Reverse Health is the first women's only 12-week weight loss program that focuses on female physiology",
      },
      { hid: 'og:url', property: 'og:url', content: 'https://reverse.health' },
      { hid: 'og:title', property: 'og:title', content: 'Reverse Health' },
      {
        hid: 'og:description',
        property: 'og:description',
        content:
          "Reverse Health is the first women's only 12-week weight loss program that focuses on female physiology.",
      },
      {
        hid: 'facebook-domain-verification',
        name: 'facebook-domain-verification',
        content: 'pbnzferzi10qee09g49f28pcvhvgbz',
      },
    ],
    link: [{ hid: 'favicon', rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }],
  };
};
